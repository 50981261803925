import React from "react";
import facebook from '../facebook.svg'
import twitter from '../twitter.svg'
import instagram from '../instagram.svg'
import linkedin from '../linkedin.svg'

function Footer() {
    return (
        <footer className="bg-[#FAF3E0] text-[#4B2E2C] py-10"> 
            <div className="max-w-7xl mx-auto px-6 grid grid-cols-1 md:grid-cols-3 gap-8">
                <div>
                    <h3 className="text-lg font-semibold mb-4">Navigation</h3>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:underline">Acceuil</a></li>
                        <li><a href="#" className="hover:underline">À propos</a></li>
                        <li><a href="#" className="hover:underline">Nos Formules</a></li>
                        <li><a href="#" className="hover:underline">Processus</a></li>
                        <li><a href="#" className="hover:underline">Contact</a></li>
                    </ul>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Contact</h3>
                    <p className="mb-2">Email: <a href="mailto:contact@noxzi-dev.fr" className="hover:underline">contact@noxzi-dev.fr</a></p>
                    <p className="mb-2">Téléphone: <a href="tel:+33123456789" className="hover:underline">+33 1 23 45 67 89</a></p>
                    <p>Adresse: 123 Rue du Café, Paris, France</p>
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Suivez-nous</h3>
                    <div className="flex space-x-4">
                      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="Facebook" className="h-6 w-6" />
                      </a>
                      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter" className="h-6 w-6" />
                      </a>
                      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" className="h-6 w-6" />
                      </a>
                      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" className="h-6 w-6" />
                      </a>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-center border-t-4 border-[#4B2E2C]">
            <p className="text-sm mt-4">
              &copy; 2024 DailyBrew. Projet fictif développé par <a href="https://noxzi-dev.fr" target="_blank" rel="noopener noreferrer" className="hover:underline">Noxzi-Dev</a>. Tous droits réservés.
            </p>
            </div>
        </footer>
    )
}

export default Footer