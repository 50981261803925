import React from "react";

function Testimonials({ testimonials  }) {
    return (
        <section  id="testimonials" className="py-20 bg-[#4B2E2C]">
        <h2 className="text-4xl font-semibold text-center mb-12 text-white">Avis de Nos Clients</h2>
        <div className="max-w-7xl mx-auto grid gap-10 md:grid-cols-2 lg:grid-cols-3 px-6">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6 transform transition duration-500 hover:scale-105">
              <div className="flex items-center mb-4">
                <img className="w-16 h-16 rounded-full object-cover mr-4" src={testimonial.image} alt={testimonial.name} />
                <div>
                  <h3 className="text-xl font-bold text-[#4B2E2C]">{testimonial.name}</h3>
                  <div className="text-[#D4A373]">{testimonial.rating}</div>
                </div>
              </div>
              <p className="text-gray-700 mt-4">{testimonial.feedback}</p>
            </div>
          ))}
        </div>
      </section>
    )
}

export default Testimonials