import React from 'react';
import ImgHeader from '../676001fe-34f8-44dd-85e8-7366c26e9e50.png';

function Header() {
  return (
    <header className="relative h-screen bg-no-repeat bg-center bg-cover bg-[#181413] " style={{ backgroundImage: `url(${ImgHeader})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-9 flex flex-col items-center justify-center h-full text-center text-white">
        <h1 className="text-6xl font-bold">Savourez le meilleur café avec DailyBrew</h1>
        <p className="mt-4 text-2xl">Le café fraîchement torréfié livré directement chez vous</p>
        <a href="#subscriptions" className="mt-8 bg-[#D4A373] text-[#4B2E2C] py-4 px-8 rounded hover:bg-[#C58E6D] transition duration-300">
            Nos Formules d'Abonnement
        </a>
      </div>
    </header>
  );
}

export default Header;
