import React, { useState } from 'react';

function SignUpForm() {


  return (
    <section className="py-20 text-[#FAF3E0] bg-[#4B2E2C]">
      <div className="max-w-lg mx-auto px-6">
        <h2 className="text-4xl font-semibold text-center mb-4 text-[#FAF3E0]">Demandez un Essai Gratuit</h2>
        <p className="text-center text-white mb-8">
          Inscrivez-vous dès aujourd'hui pour recevoir un premier mois de café fraîchement torréfié chez vous. Complétez simplement le formulaire ci-dessous.
        </p>
        <form className="bg-white p-8 rounded-lg shadow-lg text-[#4B2E2C]">
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-[#4B2E2C] font-semibold mb-2">Prénom</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-[#4B2E2C] font-semibold mb-2">Nom</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-[#4B2E2C] font-semibold mb-2">Numéro de Téléphone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-[#4B2E2C] font-semibold mb-2">Adresse E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="coffeeType" className="block text-[#4B2E2C] font-semibold mb-2">Consommation de Café</label>
            <select
              id="coffeeType"
              name="coffeeType"
              required
              className="w-full p-3 border border-gray-300 rounded"
            >
              <option value="">Sélectionnez une option</option>
              <option value="espresso">Quotidienne</option>
              <option value="filter">Hebdomadaire</option>
              <option value="french_press">Occasionnelle</option>
              <option value="other">Autre</option>
            </select>
          </div>
          <button type="submit" className="w-full bg-[#4B2E2C] text-white py-3 rounded font-semibold hover:bg-[#3a2524] transition duration-300">
            Demander un Essai Gratuit
          </button>
        </form>
      </div>
    </section>
  );
}

export default SignUpForm;
