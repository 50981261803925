import React from 'react';
import select from '../select.jpeg'
import Torrefaction from '../Torrefaction2.jpeg'
import emballage from '../emballage.jpeg'

function ProcessSection() {
  const processSteps = [
    {
      title: 'Sélection des Grains',
      description: 'Nous sélectionnons les meilleurs grains de café provenant des meilleures plantations du monde. Chaque grain est soigneusement choisi pour garantir une qualité exceptionnelle.',
      image: select
    },
    {
      title: 'Torréfaction',
      description: 'Nos experts torréfient les grains pour révéler leur pleine saveur, avec une attention particulière aux détails. La torréfaction est cruciale pour obtenir le goût parfait.',
      image: Torrefaction
    },
    {
      title: 'Emballage',
      description: 'Le café est soigneusement emballé pour garantir sa fraîcheur et sa qualité jusqu’à votre porte. Nous utilisons des matériaux d’emballage de haute qualité pour préserver tous les arômes.',
      image: emballage
    }
  ];

  return (
    <section className="py-20 bg-[#FAF3E0]">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-4xl font-semibold text-center mb-8 text-[#4B2E2C]">Notre Processus</h2>
        <p className="text-center text-gray-700 mb-12">
          Découvrez les étapes de notre processus de préparation du café, de la sélection des grains à l'emballage. Chaque étape est réalisée avec soin pour garantir un café d'exception.
        </p>
        <div className="space-y-12">
          {processSteps.map((step, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row items-center">
              <img className="w-full md:w-1/2 h-64 object-cover" src={step.image} alt={step.title} />
              <div className="p-6 w-full md:w-1/2">
                <h3 className="text-2xl font-bold text-[#4B2E2C] mb-4">{step.title}</h3>
                <p className="text-gray-700">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

  );
}

export default ProcessSection;
