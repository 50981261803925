import React, { useState } from 'react';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#4B2E2C] fixed w-full z-10 top-0 shadow-lg">
      <div className="container mx-auto flex items-center justify-between py-4">
        <div className="text-[#F5F3F4] font-bold text-xl pl-4">
          DailyBrew
        </div>
        
        <div className="space-x-2 md:hidden mr-4">
          <button onClick={toggleMenu} className="text-[#F5F3F4] focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        
        <div className={`md:flex items-center space-x-4 ${isOpen ? 'hidden' : 'hidden'} md:block`}>
          <a href="#subscriptions" className="text-[#D4A373] hover:text-[#F5F3F4] block md:inline-block">Nos Formules</a>
          <a href="#testimonials" className="text-[#D4A373] hover:text-[#F5F3F4] block md:inline-block">Témoignages</a>
          <a href="#process" className="text-[#D4A373] hover:text-[#F5F3F4] block md:inline-block">Processus</a>
        </div>
        
        <a href="#signup" className="bg-[#D4A373] text-[#4B2E2C] py-2 px-4 rounded hover:bg-[#C58E6D] hidden md:inline-block">
          Essai Gratuit
        </a>
      </div>
      
      {isOpen && (
        <div className="md:hidden bg-[#4B2E2C] text-right">
          <a href="#subscriptions" className="block py-2 px-4 text-[#D4A373] hover:text-[#F5F3F4]">Nos Formules</a>
          <a href="#testimonials" className="block py-2 px-4 text-[#D4A373] hover:text-[#F5F3F4]">Témoignages</a>
          <a href="#process" className="block py-2 px-4 text-[#D4A373] hover:text-[#F5F3F4]">Processus</a>
          <a href="#signup" className="block py-2 px-4 text-[#D4A373] hover:bg-[#C58E6D]">Essai Gratuit</a>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
