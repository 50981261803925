import React from "react";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import SubscriptionPlans from "./components/SubscriptionPlans";
import Testimonials from "./components/Testimonials";
import ProcessSection from "./components/ProcessSection";
import SignUpForm from "./components/SignUpForm";
import Footer from "./components/Footer";

import Img250 from "./250.jpeg"
import Img500 from "./500.jpeg"
import Img1 from "./1.jpeg"
import jeanDupont from './jean-dupont.jpeg'


function App() {
  const plans = [
    {
      title: 'Découverte',
      description: 'Le parfait point de départ pour explorer nos cafés. Recevez 250g de café par mois.',
      price: '9.99€/mois',
      image: Img250,
      weight: '250g',
      cta: 'Choisir cette formule'
    },
    {
      title: 'Gourmet',
      description: 'Pour les vrais amateurs, recevez 500g de café premium chaque mois.',
      price: '19.99€/mois',
      image: Img500, 
      weight: '500g',
      cta: 'Choisir cette formule'
    },
    {
      title: 'Connaisseur',
      description: 'Découvrez des saveurs rares et exotiques. Recevez 1kg de café sélectionné.',
      price: '29.99€/mois',
      image: Img1, 
      weight: '1kg',
      cta: 'Choisir cette formule'
    },
  ];
  const testimonials = [
    {
      name: 'Jean Dupont',
      feedback: 'Un café délicieux, livré rapidement. Une expérience que je recommande à tous les amateurs de café !',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    },
    {
      name: 'Marie Curie',
      feedback: 'Le café est toujours frais et parfaitement torréfié. DailyBrew a changé mes matins !',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    },
    {
      name: 'Claude Monet',
      feedback: 'Une qualité incroyable, un goût sublime. Je suis abonné depuis des mois et je suis conquis.',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    },
    {
      name: 'Jean Dupont',
      feedback: 'Un café délicieux, livré rapidement. Une expérience que je recommande à tous les amateurs de café !',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    },
    {
      name: 'Marie Curie',
      feedback: 'Le café est toujours frais et parfaitement torréfié. DailyBrew a changé mes matins !',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    },
    {
      name: 'Claude Monet',
      feedback: 'Une qualité incroyable, un goût sublime. Je suis abonné depuis des mois et je suis conquis.',
      image: jeanDupont,
      rating: '⭐⭐⭐⭐⭐'
    }
  ];

  return (
    <div className="font-sans">
      <Navbar />
      <Header />
      <SubscriptionPlans plans={plans} />
      <Testimonials testimonials={testimonials}/>
      <ProcessSection />
      <SignUpForm />
      <Footer />
    </div>
  )
}

export default App