import React from 'react';

function SubscriptionPlans({ plans }) {
  return (
    <section id='subscriptions' className="py-20 bg-[#FAF3E0] text-center">
      <h2 className="text-4xl font-semibold mb-12 text-[#4B2E2C]">Nos Formules d'Abonnement</h2>
      <div className="max-w-7xl mx-auto grid gap-10 md:grid-cols-2 lg:grid-cols-3 px-6">
        {plans.map((plan, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105">
              <img className="w-full h-64 object-cover" src={plan.image} alt={plan.title} />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-[#4B2E2C]">{plan.title}</h3>
                <p className="mt-4 text-gray-600">{plan.description}</p>
                <div className='mt-6 text-2xl font-semibold text-[#4B2E2C]'>{plan.weight}</div>
                <div className="mt-6 text-3xl font-bold text-[#D4A373]">{plan.price}</div>
                <button className="mt-8 w-full bg-[#D4A373] text-[#4B2E2C] py-3 px-6 rounded hover:bg-[#C58E6D] transition duration-300">
                  {plan.cta}
                </button>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default SubscriptionPlans;
